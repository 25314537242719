import React, { useEffect, useState } from "react";
import classes from "./TopicFeedbackModal.module.scss";
import { ReactComponent as ThumbsUp } from "./icons/ThumbsUp.svg";
import TopicFeedbackModel from "./TopicFeedbackModal";

const TopicFeedbackIcons = ({ messageHashId, socket }) => {
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  // eslint-disable-next-line
  const [feedbackType, setFeedbackType] = useState(null); // 'up' or 'down'
  const [openFM, setOpenFM] = useState(false);

  const handleThumbsUp = () => {
    setFeedbackGiven(true);
    setFeedbackType("up");
  };

  const handleThumbsDown = () => {
    setFeedbackGiven(true);
    setFeedbackType("down");
  };

  const showModal = () => {
    setOpenFM(true);
  };

  const hideModal = () => {
    setOpenFM(false);
  };

  const handleSubmit = (ratingComment) => {
    if (ratingComment) {
      socket.emit("feedback", {
        message_hash_id: messageHashId,
        feedback_text: ratingComment,
        feedback_type: "topic",
      });
    }
    setFeedbackSubmitted(true);

    hideModal();
  };

  useEffect(() => {
    if (feedbackType) {
      socket.emit("feedback", {
        message_hash_id: messageHashId,
        rating: feedbackType,
        feedback_type: "topic",
      });
    }
  }, [feedbackType, messageHashId, socket]);

  return (
    <div className={classes.TopicFeedbackIcons}>
      <div className={classes.FeedbackIcons}>
        <div className={classes.Label}>
          {feedbackGiven ? "Thanks!" : "Was my response helpful?"}
        </div>
        <div className={classes.Thumbs}>
          {feedbackGiven ? (
            feedbackSubmitted ? (
              <span className="selected">Received</span>
            ) : (
              <span onClick={showModal}>Give Feedback</span>
            )
          ) : (
            <>
              <span onClick={handleThumbsUp}>
                <ThumbsUp />
              </span>
              <span onClick={handleThumbsDown}>
                <ThumbsUp style={{ rotate: "180deg" }} />
              </span>
            </>
          )}
        </div>
      </div>
      <TopicFeedbackModel
        isOpen={openFM}
        closeHandler={hideModal}
        handleSubmit={handleSubmit}
        messageHashId={messageHashId}
      />
    </div>
  );
};

export default TopicFeedbackIcons;
