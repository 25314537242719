/**
 * Tooltip Component
 *
 * Props:
 * - children (ReactNode): The element that triggers the tooltip.
 * - content (string): The content to display inside the tooltip.
 * - position (string): Position of the tooltip relative to the child element.
 *   Options: "top", "topRight", "topLeft", "right", "bottom", "bottomRight", "bottomLeft", "left". Default is "top".
 * - alwaysVisible (boolean): If true, the tooltip is always visible. Default is false.
 * - boxStyle (object): Custom styles to apply to the tooltip wrapper. Default is undefined.
 *
 * Usage:
 *
 * <Tooltip content="Tooltip text" position="top">
 *   <button>Hover over me</button>
 * </Tooltip>
 *
 * <Tooltip content="Always visible tooltip" alwaysVisible={true} position="bottom">
 *   <span>Always visible</span>
 * </Tooltip>
 *
 * <Tooltip content="Custom styled tooltip" boxStyle={{ margin: '20px', display: 'inline-block' }} position="top">
 *   <span>Custom style</span>
 * </Tooltip>
 */
import React, { useState, useRef } from "react";
import styles from "./Tooltip.module.css"; // Import CSS as module

const Tooltip = ({
  children,
  content,
  position = "top",
  alwaysVisible = undefined,
  boxStyle = {},
}) => {
  const [visible, setVisible] = useState(false);
  const debounceTimeout = useRef(null);

  const debounce = (fn, delay) => {
    return () => {
      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(fn, delay);
    };
  };

  const showTooltip = debounce(() => setVisible(true), 100);
  const hideTooltip = debounce(() => setVisible(false), 100);

  const handleMouseEnter = () => {
    if (alwaysVisible === null || alwaysVisible === undefined) {
      showTooltip();
    }
  };

  const handleMouseLeave = () => {
    if (alwaysVisible === null || alwaysVisible === undefined) {
      hideTooltip();
    }
  };

  return (
    <div
      className={styles.tooltipWrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {(alwaysVisible || visible) && (
        <div
          className={`${styles.tooltipBox} ${
            styles[
              `tooltip${position.charAt(0).toUpperCase() + position.slice(1)}`
            ]
          }`}
          style={boxStyle}
        >
          {content}
          <div
            className={`${styles.tooltipArrow} ${
              styles[
                `tooltipArrow${
                  position.charAt(0).toUpperCase() + position.slice(1)
                }`
              ]
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
