const uuid = () => {
  if (
    typeof window !== "undefined" &&
    window.crypto &&
    window.crypto.getRandomValues
  ) {
    // Generate a UUID using the crypto API
    const buffer = new Uint16Array(8);
    window.crypto.getRandomValues(buffer);

    const hexValues = Array.from(buffer, (num) =>
      num.toString(16).padStart(4, "0")
    );

    // Set the version (4) and variant (random)
    hexValues[6] = (parseInt(hexValues[6], 16) & 0x0fff) | 0x4000;
    hexValues[8] = (parseInt(hexValues[8], 16) & 0x3fff) | 0x8000;

    return `${hexValues[0]}${hexValues[1]}-${hexValues[2]}-${hexValues[3]}-${hexValues[4]}-${hexValues[5]}${hexValues[6]}${hexValues[7]}`;
  } else {
    // Fallback for environments without crypto.getRandomValues
    let uuid = "";

    for (let i = 0; i < 32; i++) {
      uuid += Math.floor(Math.random() * 16).toString(16);
    }

    // Insert hyphens at specified positions
    uuid = `${uuid.substr(0, 8)}-${uuid.substr(8, 4)}-${uuid.substr(
      12,
      4
    )}-${uuid.substr(16, 4)}-${uuid.substr(20)}`;

    return uuid;
  }
};

export default class Message {
  constructor(payload) {
    this.id = payload.id || uuid();
    this.text =
      (payload.text instanceof Array ? payload.text[0] : payload.text) || null;
    this.type = payload.type || "text";
    this.mode = payload.mode || ""; // Mode: bot, user, agent, status
    this.modeType = `${this.mode}_${this.type}`;
    this.parameters = payload.parameters || {};
    this.url = payload.url || "";
    this.thumbnailUrl = payload.thumbnail_url || "";
    this.title = payload.title || "";
    this.agentName = payload.agentName || "";
    this.isFileMessage = payload.isFileMessage || false;
    this.attachment = payload.attachment || {};
    this.messageHashId = payload.message_hash_id || "";
    this.showFeedback = payload.show_feedback || false;
  }
}
