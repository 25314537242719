import React, { useState } from "react";
import classes from "./FeedbackModal.module.scss";
import Modal from "../Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import { setShowFeedbackModel } from "../../store/actions/general";

const FeedbackModal = ({ isOpen, closeHandler, config, socket }) => {
  const dispatch = useDispatch();
  const { isAgentConnected } = useSelector((state) => state.zendesk);
  const [rating, setRating] = useState(null);
  const [ratingReason, setRatingReason] = useState(null);
  const [ratingComment, setRatingComment] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleRatingClick = (ratingValue) => {
    setRating(ratingValue);
  };

  const handleRatingReasonClick = (reason) => {
    setRatingReason(reason);
  };

  const handleFeedbackSubmit = () => {
    socket.emit("feedback", {
      feedback_type: "session",
      rating,
      reason: ratingReason,
      feedback_text: ratingComment,
    });
    const endEvent = isAgentConnected ? { from: "user" } : { from: "agent" };
    socket.emit("end-chat", endEvent);
    setShowMessage(true);
    setTimeout(() => {
      closeHandler();
      setShowMessage(false);
      dispatch(setShowFeedbackModel(false));
    }, 2000);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      contentStyle={{ overflowY: "scroll", height: "84%" }}
    >
      <div className={classes.FeedbackModalContainer}>
        <h3 className={classes.FeedbackModalTitle}>Give Feedback</h3>
        <div className={classes.FeedbackModalContent}>
          <div className={classes.Ratings}>
            <div className={classes.Label}>
              How would you rate your overall experience?
            </div>
            <div className={classes.Thumbs}>
              <img
                src={config.rating_icons.negative}
                alt="Negative"
                className={`${classes.RatingIcon} ${
                  rating && rating !== "negative"
                    ? classes.RatingIconDisabled
                    : ""
                }`}
                onClick={() => handleRatingClick("negative")}
              />
              <img
                src={config.rating_icons.neutral}
                alt="Neutral"
                className={`${classes.RatingIcon} ${
                  rating && rating !== "neutral"
                    ? classes.RatingIconDisabled
                    : ""
                }`}
                onClick={() => handleRatingClick("neutral")}
              />
              <img
                src={config.rating_icons.positive}
                alt="Positive"
                className={`${classes.RatingIcon} ${
                  rating && rating !== "positive"
                    ? classes.RatingIconDisabled
                    : ""
                }`}
                onClick={() => handleRatingClick("positive")}
              />
            </div>
          </div>
          {rating && (
            <div className={classes.Ratings}>
              <div className={classes.Label}>
                Thank you for rating our app! We'd love to know the reason
                behind your rating.
              </div>
              <div className={classes.RatingReasons}>
                {config?.default_reasons?.map((label) => (
                  <span
                    key={label}
                    className={`${classes.RatingReasonOptions} ${
                      ratingReason === label ? "selected" : ""
                    }`}
                    onClick={() => handleRatingReasonClick(label)}
                  >
                    {label}
                  </span>
                ))}
                <span
                  key="Other"
                  className={`${classes.RatingReasonOptions} ${
                    ratingReason === "Other" ? "selected" : ""
                  }`}
                  onClick={() => handleRatingReasonClick("Other")}
                >
                  Other
                </span>
              </div>
            </div>
          )}
          {ratingReason === "Other" && (
            <div className={classes.Ratings}>
              <div className={classes.Label}>
                What would you like to share with us?
              </div>
              <div className={classes.RatingButtons}>
                <textarea
                  className={classes.RatingCommentBox}
                  rows={6}
                  placeholder="Any other suggestions (Optional)"
                  value={ratingComment}
                  onChange={(e) => setRatingComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          )}
          <div className={classes.Buttons}>
            <button
              className={classes.ButtonFeedback}
              onClick={handleFeedbackSubmit}
              disabled={!(rating && ratingReason)}
            >
              Send feedback
            </button>
          </div>
          {showMessage && (
            <span className={classes.SuccessMessage}>
              Thank you for your feedback!
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
