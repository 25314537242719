import React from "react";
import classes from "./Modal.module.scss";

const Modal = ({ isOpen, onClose, children, contentWidth, contentStyle }) => {
  if (!isOpen) return null;

  return (
    <div className={classes.ModalContainer}>
      <div className={classes.ModalMask} onClick={onClose}></div>
      <div
        className={classes.ModalContent}
        style={{ ...contentStyle, width: contentWidth }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
