import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import incomingClassesModule from "./Incoming.module.scss";
import messageBubbleClassesModule from "../MessageBubble.module.scss";
import TopicFeedbackIcons from "../../../../components/TopicFeedbackModal/TopicFeedbackIcons";

function Incoming(props) {
  const incomingClasses = [
    incomingClassesModule.Incoming,
    messageBubbleClassesModule.MessageBubble,
  ];
  const configIncoming = window.sutherland_variables.ui.incoming_messages;
  const bubbleStyles = configIncoming
    ? {
        color: configIncoming.text_color,
        backgroundColor: configIncoming.bubble_color,
        fontFamily: configIncoming.font_family,
        boxShadow: configIncoming.shadow,
      }
    : {};

  if (props.isTop) {
    incomingClasses.push(incomingClassesModule.top);
  }
  if (props.isBottom) {
    incomingClasses.push(incomingClassesModule.bottom);
  }

  return (
    <div className={messageBubbleClassesModule.incomingOuter}>
      <div className={incomingClasses.join(" ")} style={bubbleStyles}>
        <ReactMarkdown
          children={props.message.text}
          remarkPlugins={[remarkGfm]}
          linkTarget="_blank"
        />
      </div>
      {props.message.showFeedback && props.message.messageHashId && (
        <TopicFeedbackIcons
          messageHashId={props.message.messageHashId}
          socket={props.socket}
        />
      )}

      {props.isBottom ? (
        <div className={messageBubbleClassesModule.timeStamp}>
          {props.timeStamp}
        </div>
      ) : null}
    </div>
  );
}

Incoming.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string,
};

export default Incoming;
