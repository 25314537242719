import React, { useState } from "react";
import classes from "./TopicFeedbackModal.module.scss";
import Modal from "../Modal/Modal";
import { ReactComponent as ArrowLeft } from "./icons/ArrowLeft.svg";

const TopicFeedbackModal = ({
  isOpen,
  closeHandler,
  handleSubmit,
  messageHashId,
}) => {
  const [ratingComment, setRatingComment] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleFeedbackSubmit = () => {
    setShowMessage(true);
    setTimeout(() => {
      closeHandler();
      setShowMessage(false);
      handleSubmit(ratingComment);
    }, 2000);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      contentStyle={{ overflowY: "scroll", height: "84%" }}
    >
      <div className={classes.FeedbackModalContainer}>
        <div className={classes.FeedbackModalContent}>
          <div className={classes.BackButton} onClick={closeHandler}>
            {" "}
            <ArrowLeft /> Back
          </div>
          <div className={classes.Heading}>Give Feedback</div>
          <div className={classes.Ratings}>
            <div className={classes.Label}>
              What would you like to share with us?
            </div>
            <div className={classes.RatingButtons}>
              <textarea
                className={classes.RatingCommentBox}
                rows={6}
                placeholder="Any other suggestions (Optional)"
                value={ratingComment}
                onChange={(e) => setRatingComment(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className={classes.Buttons}>
            <button
              className={classes.ButtonFeedback}
              onClick={handleFeedbackSubmit}
            >
              Send feedback
            </button>
          </div>
          {showMessage && (
            <span className={classes.SuccessMessage}>
              Thank you for your feedback!
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TopicFeedbackModal;
