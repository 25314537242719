import React from "react";
import classes from "./TopicFeedbackModal.module.scss";
import { useDispatch } from "react-redux";
import { setShowChatbotPerformanceFeedback } from "../../store/actions/general";

const ChatbotPerformanceFeedback = ({ socket }) => {
  const components = window.sutherland_variables.components;
  const config = components["feedback"];
  const dispatch = useDispatch();

  const submitFeedback = (rating) => {
    socket.emit("feedback", {
      feedback_type: "mid-session",
      rating,
    });
    dispatch(setShowChatbotPerformanceFeedback(false));
  };

  return (
    <div style={{ padding: "10px" }}>
      <h1
        style={{
          fontSize: "16px",
          fontWeight: "500",
          textWrap: "auto",
          letterSpacing: "0.4px",
        }}
      >
        How would you rate the Chatbot's performance?
      </h1>
      <div className={classes.ChatbotFeedbackIconContainer}>
        <img
          className={classes.RatingIcon}
          src={config?.rating_icons?.negative}
          alt="Negative"
          onClick={() => {
            submitFeedback("negative");
          }}
        />
        <img
          className={classes.RatingIcon}
          src={config?.rating_icons?.neutral}
          alt="Neutral"
          onClick={() => {
            submitFeedback("neutral");
          }}
        />
        <img
          className={classes.RatingIcon}
          src={config?.rating_icons?.positive}
          alt="Positive"
          onClick={() => {
            submitFeedback("positive");
          }}
        />
      </div>
    </div>
  );
};
export default ChatbotPerformanceFeedback;
