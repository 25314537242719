import {
  SET_WIDGET_IS_OPEN,
  SET_DRAWER_IS_OPEN,
  SET_ENDCHAT_IS_OPEN,
  SET_AVATAR_URL,
  SET_PRECHAT_ENABLED,
  SET_PRECHAT_FORM_OPENED,
  SET_AGENT_NAME,
  SET_SESSION_ID,
  SET_SOCKET_ID,
  MUI_FONT_LOADED,
  SET_RESET_CHAT,
  END_CHAT,
  SET_SESSION_START_TIME,
  SET_ALERT_IS_OPEN,
  SET_FINGERPRINT_ID,
  SET_SHOW_FEEDBACK_MODEL,
  SET_SHOW_CHATBOT_PERFORMANCE_FEEDBACK,
} from "../constants/general";

const initialState = {
  widgetIsOpen: false,
  drawerIsOpen: false,
  endChatIsOpen: false,
  sessionId: "",
  socketId: "",
  fingerprintId: "",
  prechatEnabled: false,
  prechatFormOpened: true,
  avatarUrl: "",
  agentName: "",
  muiFontLoaded: false,
  resetChat: null,
  endChat: false,
  sessionStartTime: new Date(),
  alertIsOpen: true,
  showFeedbackModel: false,
  showChatbotPerformanceFeedback: false,
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId,
      };
    case SET_SOCKET_ID:
      return {
        ...state,
        socketId: action.socketId,
      };

    case SET_FINGERPRINT_ID:
      return {
        ...state,
        fingerprintId: action.fingerprintId,
      };
    case SET_WIDGET_IS_OPEN:
      return {
        ...state,
        widgetIsOpen: action.widgetIsOpen,
      };
    case SET_ALERT_IS_OPEN:
      return {
        ...state,
        alertIsOpen: action.alertIsOpen,
      };
    case SET_DRAWER_IS_OPEN:
      const newState = {
        ...state,
        drawerIsOpen: action.drawerIsOpen,
      };
      return newState;
    case SET_PRECHAT_ENABLED:
      return {
        ...state,
        prechatEnabled: action.prechatEnabled,
      };

    case SET_PRECHAT_FORM_OPENED:
      return {
        ...state,
        prechatFormOpened: action.prechatFormOpened,
      };

    case SET_AVATAR_URL:
      return {
        ...state,
        avatarUrl: action.avatarUrl,
      };

    case SET_AGENT_NAME:
      return {
        ...state,
        agentName: action.agentName,
      };

    case MUI_FONT_LOADED:
      return {
        ...state,
        muiFontLoaded: action.muiFontLoaded,
      };

    case SET_RESET_CHAT:
      return {
        ...state,
        resetChat: action.resetChat,
        sessionStartTime: action.resetChat
          ? new Date()
          : state.sessionStartTime,
      };

    case END_CHAT:
      return {
        ...state,
        endChat: action.endChat,
      };

    case SET_SESSION_START_TIME:
      return {
        ...state,
        sessionStartTime: new Date(),
      };

    case SET_ENDCHAT_IS_OPEN:
      return {
        ...state,
        endChatIsOpen: action.endChatIsOpen,
      };

    case SET_SHOW_FEEDBACK_MODEL:
      return {
        ...state,
        showFeedbackModel: action.showFeedbackModel,
      };

    case SET_SHOW_CHATBOT_PERFORMANCE_FEEDBACK:
      return {
        ...state,
        showChatbotPerformanceFeedback: action.showChatbotPerformanceFeedback,
      };

    default:
      return state;
  }
};

export default general;
