import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FontFaceObserver from "fontfaceobserver";
import PrechatForm from "../PrechatForm/PrechatForm";
import MainContainer from "../MainContainer/MainContainer";
import ChatLauncher from "../../components/ChatLauncher/ChatLauncher";
import { setMuiFontLoaded } from "../../store/actions/general";

function RootContainer() {
  const { prechatEnabled, prechatFormOpened, widgetIsOpen } = useSelector(
    (state) => state.general
  );
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);

  const Container = () => {
    if (prechatEnabled && prechatFormOpened) {
      return <PrechatForm />;
    }

    return <MainContainer setSocketRef={setSocket} />;
  };

  useEffect(() => {
    const font = new FontFaceObserver("Material Icons");

    dispatch(setMuiFontLoaded(false));
    font
      .load(null, 10000)
      .then(() => {
        dispatch(setMuiFontLoaded(true));
      })
      .catch((error) => {
        dispatch(setMuiFontLoaded(false));
      });
  }, [dispatch]);

  return useMemo(() => {
    return (
      <div>
        {widgetIsOpen ? <Container /> : null}
        <ChatLauncher socket={socket} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetIsOpen]);
}

export default RootContainer;
