import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import classes from "./MainContainer.module.scss";
import Header from "../../components/Header/Header";
import FileUploadModal from "../../components/FileUploadModal/FileUploadModal";
import FeedbackModal from "../../components/FeedbackModal/FeedbackModal";
import EndChatModal from "../../components/EndChatModal/EndChatModal";
import MessageComposer from "../../components/MessageComposer/MessageComposer";
import MessageList from "../MessageList/MessageList";
import Typing from "../Typing/Typing";
import QuickResponseContainer from "../QuickResponseContainer/QuickResponseContainer";
import Message from "../../models/Message";
import QuickResponse from "../../models/QuickResponse";
import Alert from "../../components/Alert/Alert";
import {
  setSessionId,
  setAgentName,
  setResetChat,
  setEndChat,
  setEndChatIsOpen,
  setFingerprintId,
  setWidgetIsOpen,
  setAlertIsOpen,
} from "../../store/actions/general";
import {
  addMessage,
  cancelFileUploads,
  setQuickResponses,
  setMode,
  setIsTyping,
  setShowUpload,
  setShowComposer,
  setMessages,
} from "../../store/actions/messages";
import {
  setZendeskSession,
  setFileUploadRequestDetails,
  setFileUploadConfigDetails,
} from "../../store/actions/zendesk";
import { useDispatch, useSelector } from "react-redux";

const MainContainer = ({ setSocketRef }) => {
  const components = window.sutherland_variables.components;
  let composerOpen = true;
  if (components["composer"]) {
    composerOpen = components["composer"]["enabled"];
  }
  const {
    prechatEnabled,
    widgetIsOpen,
    sessionId,
    resetChat,
    endChat,
    endChatIsOpen,
    fingerprintId,
    showFeedbackModel,
  } = useSelector((state) => state.general);
  const { isAgentConnected } = useSelector((state) => state.zendesk);
  const { messages, typing, showUpload, showComposer } = useSelector(
    (state) => state.messages
  );
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  // eslint-disable-next-line
  const [fileuploadEnabled, setFileuploadEnabled] = useState(
    components["fileupload"]["enabled"]
  );
  // eslint-disable-next-line
  const [feedbackEnabled, setFeedbackEnabled] = useState(
    components["feedback"]["enabled"]
  );

  const socketRef = useRef(null);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      dispatch(setWidgetIsOpen(false));
      dispatch(setResetChat(true));
      dispatch(setEndChat(false));
      dispatch(setMode("bot"));
      dispatch(setSessionId(""));
      dispatch(setMessages([]));
      dispatch(setZendeskSession(false));
      dispatch(setAlertIsOpen(true));
    }, 4000);
  };

  const handleCloseUpload = () => {
    dispatch(setShowUpload(false));
  };

  const handleCloseEndchatModal = () => {
    dispatch(setEndChatIsOpen(false));
  };

  useEffect(() => {
    window.sutherland_variables.resetChat = resetChat;
    window.sutherland_variables.endChat = endChat;
    window.sutherland_variables.isAgentConnected = isAgentConnected;
    window.sutherland_variables.fingerprintId = fingerprintId;

    if (!socketRef.current) {
      socketRef.current = io(window.sutherland_variables.engineBaseUrl, {
        path: "/ws/chat/",
        transports: ["websocket", "polling"],
        upgrade: true,
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelayMax: 2000,
        randomizationFactor: 0.5,
        auth: {
          bot_id: window.sutherland_variables.botId, // The bot config ID
          session_id: !sessionId ? "" : sessionId,
        },
      });

      const socket = socketRef.current;
      setSocketRef(socketRef.current); // Share socket with parent

      socket.on("connected", async (data) => {
        console.log("\u25B6 connected");

        if (
          window.sutherland_variables.resetChat ||
          window.sutherland_variables.resetChat === null
        ) {
          console.log("\u25B6 initialize session");
          dispatch(setSessionId(data.session_id));

          if (!window.sutherland_variables.fingerprintId) {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            window.sutherland_variables.fingerprintId = visitorId;
            dispatch(setFingerprintId(visitorId));
          }

          // Auto send message to start convo
          console.log("\u25B6 start chat");

          socket.emit("send", {
            name: "welcome-event",
            parameters: {
              FingerprintId: window.sutherland_variables.fingerprintId,
              UserAgent: window.sutherland_variables.user_agent,
              Browser: window.sutherland_variables.browser,
              DeviceCategory: window.sutherland_variables.device,
              ScreenResolution: `${window.screen.width}x${window.screen.height}`,
              VisitorIP: window.sutherland_variables.ip_address,
              url: document.URL,
              title: document.title,
            },
          });
          dispatch(setResetChat(false));
        }
      });

      socket.on("disconnected", (data) => {
        console.log("Client disconnected.");
      });

      socket.on("message-received", (data) => {
        data.mode = data.mode.toLowerCase();
        dispatch(setMode(data.mode));
        dispatch(setIsTyping(false));
        dispatch(setShowComposer(composerOpen));

        for (const message of data.payload) {
          message.mode = data.mode;

          if (data.mode === "agent") {
            message.agentName = data.username;
          }

          switch (message.type) {
            case "chips":
              const responsesList = [];
              for (const option of message.options) {
                const newQuickResponse = new QuickResponse(option);
                responsesList.push(newQuickResponse);
              }
              dispatch(setQuickResponses(responsesList));
              break;
            case "info":
              if (data.mode === "agent") {
                dispatch(setAgentName(data.username));
              }
              message.text = `[${message.title}](${message.link})`;
              dispatch(addMessage(new Message(message)));
              break;

            case "file":
              if (data.mode === "agent") {
                dispatch(setAgentName(data.username));
              }
              message.isFileMessage = true;
              message.type = "text_agent";

              dispatch(addMessage(new Message(message)));
              break;
            case "status":
              if (
                message.event !== "ChatEnd" &&
                message.event !== "AgentChatEnd"
              ) {
                dispatch(addMessage(new Message(message)));
              }
              switch (message.event) {
                case "ChatEnd":
                  if (!window.sutherland_variables.endChat) {
                    dispatch(addMessage(new Message(message)));
                    dispatch(setEndChat(true));
                    dispatch(setZendeskSession(false));
                    setTimeout(() => {
                      if (!window.sutherland_variables.isAgentConnected) {
                        socket.emit("end-chat", {});
                      } else {
                        socket.emit("end-chat", {
                          from: "user",
                        });
                      }
                    }, 500);
                  } else {
                    socket.disconnect();
                  }
                  break;
                case "AgentChatEnd":
                  if (!window.sutherland_variables.endChat) {
                    dispatch(addMessage(new Message(message)));
                    dispatch(setEndChat(true));
                    dispatch(setZendeskSession(false));
                    setTimeout(() => {
                      socket.emit("end-chat", {
                        from: "agent",
                      });
                    }, 500);
                  } else {
                    socket.disconnect();
                  }
                  break;
                case "FileRequestCancel":
                  dispatch(cancelFileUploads());
                  break;
                case "AgentJoined":
                case "ChatRequestSuccess":
                case "QueueUpdate":
                  dispatch(setZendeskSession(true));
                  break;
                default:
                  break;
              }
              break;
            case "carousel":
              const carouselResponse = {
                ...message,
                parameters: message.options,
              };
              dispatch(addMessage(new Message(carouselResponse)));
              break;
            case "composer":
              dispatch(setShowComposer(true));
              break;
            default:
              if (
                message.type !== "error" &&
                message.type !== "liveAgentHandoff"
              ) {
                if (data.mode === "agent") {
                  dispatch(setAgentName(data.username));
                }
                dispatch(addMessage(new Message(message)));
              }
              break;
          }
        }
      });

      socket.on("agent-typing", () => {
        dispatch(setMode("agent"));
        dispatch(setIsTyping(true));
        setTimeout(() => {
          dispatch(setIsTyping(false));
        }, 1500);
      });

      socket.on("stop-typing", () => {
        dispatch(setMode("agent"));
        dispatch(setIsTyping(false));
      });

      socket.on("file-transfer", (data) => {
        dispatch(setIsTyping(false));
        const message = {
          type: "file-transfer",
          text: "",
          url: data?.url,
        };
        dispatch(addMessage(new Message(message)));
      });

      socket.on("file-transfer-success", (data) => {
        dispatch(setIsTyping(false));
        const message = {
          type: "text",
          mode: "user",
          text: "",
          isFileMessage: true,
          attachment: data?.attachment,
        };
        dispatch(addMessage(new Message(message)));
      });

      socket.on("file-upload-request", (data) => {
        dispatch(setFileUploadRequestDetails(data));
      });

      socket.on("file-upload-config", (data) => {
        dispatch(setFileUploadConfigDetails(data));
      });

      setIsLoading(false);
    }
  }, [
    dispatch,
    sessionId,
    messages,
    resetChat,
    endChat,
    isAgentConnected,
    fingerprintId,
    composerOpen,
    setSocketRef,
  ]);

  useEffect(() => {
    if (feedbackEnabled && showFeedbackModel) {
      setModalOpen(true);
    }
  }, [showFeedbackModel, feedbackEnabled, endChat]);

  if (!isLoading) {
    return (
      <div
        className={
          prechatEnabled || widgetIsOpen
            ? classes.MainContainer
            : classes.MainContainerGrowIn
        }
      >
        <div className={classes.MainContainerOuter}>
          <Header socket={socketRef.current} />
          <Alert socket={socketRef.current} />
          <FileUploadModal
            isOpen={showUpload && isAgentConnected && fileuploadEnabled}
            closeHandler={handleCloseUpload}
          />
          <FeedbackModal
            isOpen={modalOpen && feedbackEnabled}
            closeHandler={handleCloseModal}
            config={components["feedback"]}
            socket={socketRef.current}
          />
          <EndChatModal
            isOpen={endChatIsOpen}
            closeHandler={handleCloseEndchatModal}
            socket={socketRef.current}
          />
          <MessageList socket={socketRef.current} />
          {!endChat && (
            <>
              <QuickResponseContainer socket={socketRef.current} />
              <div className={classes.typeComposerSection}>
                <Typing show={typing} mode="dot-loader" typingText="" />
                <div className={classes.footer}>
                  {(showComposer || isAgentConnected) && (
                    <MessageComposer socket={socketRef.current} />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default MainContainer;
