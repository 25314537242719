export const SET_WIDGET_IS_OPEN = "SET_WIDGET_IS_OPEN";
export const SET_DRAWER_IS_OPEN = "SET_DRAWER_IS_OPEN";
export const SET_ENDCHAT_IS_OPEN = "SET_ENDCHAT_IS_OPEN";
export const SET_AVATAR_URL = "SET_AVATAR_URL";
export const SET_PRECHAT_ENABLED = "SET_PRECHAT_ENABLED";
export const SET_PRECHAT_FORM_OPENED = "SET_PRECHAT_FORM_OPENED";
export const SET_AGENT_NAME = "SET_AGENT_NAME";
export const SET_SOCKET_ID = "SET_SOCKET_ID";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const MUI_FONT_LOADED = "MUI_FONT_LOADED";
export const SET_RESET_CHAT = "SET_RESET_CHAT";
export const END_CHAT = "END_CHAT";
export const SET_SESSION_START_TIME = "SET_SESSION_START_TIME";
export const SET_ALERT_IS_OPEN = "SET_ALERT_IS_OPEN";
export const SET_FINGERPRINT_ID = "SET_FINGERPRINT_ID";
export const SET_SHOW_FEEDBACK_MODEL = "SET_SHOW_FEEDBACK_MODEL";
export const SET_SHOW_CHATBOT_PERFORMANCE_FEEDBACK =
  "SET_SHOW_CHATBOT_PERFORMANCE_FEEDBACK";
